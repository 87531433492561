import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import business from '../../Assets/business.svg';
import user from '../../Assets/user.svg';
import technology from '../../Assets/technology.svg';
import Container from 'react-bootstrap/Container';
import dots from '../../Assets/dots.svg';
import './style.css';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/Col';
import { messages } from "./../../Messages/messages.js";

const approaches = [
    {
        id:1,
        name:`${messages.approachName1}`,
        image:business,
        padding:3,
        description:`${messages.approachDescription1}`
    },
    {
        id:2,
        name:`${messages.approachName2}`,
        image:user,
        padding:4,
        description:`${messages.approachDescription2}`
    },
    {
        id:3,
        name:`${messages.approachName3}`,
        image:technology,
        padding:1,
        description:`${messages.approachDescription3}`
    },
];

export const OurApproach = () => {

    const desktop = useMediaQuery(useTheme().breakpoints.up('sm'));

  return (
    desktop?

    // LARGE SCREEN
    <div style={{paddingTop:'0px', paddingLeft:"20px", paddingRight:"20px", maxWidth:"100%"}}>
    < div style={{backgroundColor: '#F9F8F4',paddingLeft:'40px',paddingRight:'40px',paddingBottom:'20px',borderRadius: '20px',paddingTop:'20px',paddingBottom:'95px', maxWidth:"100%"}} >
        <div className='our-approach-heading' style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',paddingTop:'10px'}}>
         
                {'Our Approach'}
          
            </div>
            <div className='center' style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
            <p className='approach-description'>
               {messages.approachDescription}
            </p>
            </div>
        {/* </div> */}
        
            <div style={{paddingTop:'40px'}}>
                <Row>
                {approaches.map((a)=>
                <>
                <Col xs={12} md={4} sx={{paddingTop:'60px'}}>
                    <div>
                    <Grid container sx={{borderRadius:'10px',padding:'10px',background:'#fff',height:'200px',width: '450px'}} spacing={0}>
                        {/* <Grid item xs={12} style={{paddingBottom:0,margin:0,display:'flex',justifyContent:'center',alignItems:'center',textAlign:'left'}}> */}
                            {/* <Grid container xs={12} style={{display:'inline',justifyContent:'center',alignItems:'center',textAlign:'center'}}> */}
                            
                              <span style={{padding:0,height:'40px'}}>
                                    <img src={a.image} alt={a.name} className='approach-icon'/>
                                    </span>
                                    <span style={{paddingLeft:15,paddingTop:8,height:'40px'}}>
                                    <h6 className='approach-name'>
                                        {a.name}
                                    </h6>
                                    </span>
                                
                            {/* </Grid> */}
                        {/* </Grid> */}
                        <Grid item xs={12} md={12} sx={{paddingTop:a.padding,width: '324px'}} className='approach-subdescription'>
                       {a.description}
                        </Grid>
                        
                    </Grid>
                    </div>
                </Col>
                
                </>
                )}
                </Row>
            </div>
       
    </div>
    </div>


:

// SMALL SCREEN
<div style={{paddingTop:'0px', paddingLeft:"20px"}}>

        <div className='our-approach-heading' style={{display:'flex',justifyContent:'left',alignItems:'left',textAlign:'center',paddingLeft:'10px'}}>
         
                {'Our Approach'}
          
            </div>
            <div className='center' style={{display:'flex',justifyContent:'center',alignItems:'left',textAlign:'left',paddingLeft:'6px',paddingRight:'10px'}}>
            <p className='approach-description' style={{textAlign:'left', lineHeight:"25px"}}>
               {messages.approachDescription}
            </p>
            </div>
    <Grid item xs={12} md={12} sx={{paddingTop:'10px'}}>
        <Grid container>
            {approaches.map((a)=>
            <Grid item xs={12} md={4} sx={{paddingTop:'10px',paddingLeft:'10px',paddingRight:'20px'}}>
                <Grid container spacing={2} sx={{borderRadius:'10px',padding:'16px',background:'#fff'}}>
                    <Grid item xs={12} >
                        <Grid container xs={12} style={{display:'inline',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
                          <span style={{display:'inline-block'}}>
                                <img src={a.image} alt={a.name} className='approach-iconRes'/>
                                </span>
                                <span style={{display:'inline-block',paddingLeft:15}}>
                                <h6 className='approach-nameRes'>
                                    {a.name}
                                </h6>
                                </span>
                            
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{paddingTop:'10px'}}>
                        <p className='approach-caption' style={{justifyContent:'center', alignItems:'center', fontSize:"15px/25px" }}>
                            {a.description}
                        </p>
                    </Grid>
                    
                </Grid>
            </Grid>
            )}
        </Grid>
    </Grid>
</div>
  )
}
