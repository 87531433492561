import React from 'react';
import GridViewIcon from '@mui/icons-material/GridView';
import Logo from '../../Assets/Logo.png';
import DesignItRight from '../DesignItRight';
import Welcome from '../Welcome';
import OurServices from '../OurServices';
import OurNear from '../OurNear';
import  {SystemLandscape} from '../SystemLandscape';
import { OurApproach } from '../OurApproach';
import { DarsanFooter}  from '../DarsanFooter';
import OurProducts from '../OurProducts';
import Client from '../Clients';
import './style.css';

const Navbar = () => {

    const [isNavExpanded, setIsNavExpanded] = React.useState(false);

  return (
    <>
    <div style={{paddingLeft:'70px',paddingRight:'60px',paddingTop:'30px', paddingBottom:'20px'}}>
    <nav className="navigation" >
    <a href="#" className="brand-name">
     <img src={Logo} alt="Darsan" width= '170px'
height='50px' style={{paddingBottom:'10px'}}/>
    </a>
    <button
      className="hamburger"
      onClick={() => {
        setIsNavExpanded(!isNavExpanded)
      }}
    >
      <GridViewIcon/>
    </button>
    <div
      className={
        isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
      }
    >
      <ul>
        <li onClick={() => {
        setIsNavExpanded(false)
      }}>
          <a href="#">Home</a>
        </li>
        <li onClick={() => {
        setIsNavExpanded(false)
      }}>
          <a href="#s">Services</a>
        </li>
        <li onClick={() => {
        setIsNavExpanded(false)
      }}>
          <a href="#p">Products</a>
        </li>
        <li onClick={() => {
        setIsNavExpanded(false)
      }}>
            <a href="#f">Contact us</a>
        </li>
      </ul>
    </div>
  </nav>
  </div>
  
  <div style={{paddingTop:'30px'}}>
  <DesignItRight id="d"/>
  </div>
 
      <Welcome id="w"/>
      <div id="s">
      <OurServices />
      </div>
      <div style={{paddingTop:'50px'}}>
      <SystemLandscape id="l"/>
      </div>

      <OurNear id="n"/>
      <div style={{paddingTop:'50px'}}>
      <OurApproach id="a"/>
      </div>
      <div id="p">
      <OurProducts />
      </div>
      <Client id="c"/>
      <div id="f">
      <DarsanFooter />
      </div>
      </>
  )
}

export default Navbar