import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import Superior from "../../Assets/Superior.png";
import Cost from "../../Assets/Cost.png";
import "./index.css";
import { messages } from "../../Messages/messages";

const OurServices = () => {
  const desktop = useMediaQuery(useTheme().breakpoints.up("sm"));
  return desktop ? (
    // LARGE SCREEN
    <Grid container spacing={2} style={{ paddingTop: "20px" }}>
      <Grid
        item
        xs={12}
        md={12}
        style={{ textAlign: "center" }}
        alignItems="center"
        justifyContent="center"
        className="animate"
      >
        <span className="our">Our Near-s</span>
        <span className="It">IT</span>
        <span className="our">e solutions</span>
      </Grid>
      <Grid item md={3} />
      <Grid
        item
        xs={12}
        md={6}
        style={{ textAlign: "center" }}
        alignItems="center"
        justifyContent="center"
      >
        <p
          className="Nearsite-paragraph"
          style={{ fontSize: "17px", lineHeight: "29px", textAlign: "justify" }}
        >
          {messages.nearsiteParagraph}
        </p>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "60px",
          display: "flex",
          position: "relative",
          zIndex: 2,
          paddingLeft: "25px",
        }}
      >
        <Grid
          item
          md={4}
          className="Superior"
          style={{ paddingLeft: "30px", paddingRight: "30px", height: "256px" }}
        >
          {messages.superior}
          <div className="Superiorcontent">
            <span style={{ width: "65%" }}>{messages.superiorContent}</span>

            <span
              style={{
                float: "right",
                top: 70,
                position: "absolute",
                zIndex: 7,
                top: "42%",
                left: "31%",
              }}
            >
              <img src={Superior} alt="IT services for small to enterprise clients" className="SuperiorImg" />
            </span>
          </div>
        </Grid>
        <Grid item md={1} />
        <Grid
          item
          md={4}
          className="Cost"
          style={{ paddingLeft: "30px", height: "256px" }}
        >
          {messages.cost}
          <div className="Costcontent">
            <span style={{ width: "70%" }}>{messages.costContent}</span>
            <span
              style={{
                float: "right",
                top: 70,
                position: "absolute",
                zIndex: 7,
                top: "42%",
                left: "73.5%",
              }}
            >
              <img src={Cost} alt="Best IT consulting rates" className="CostImg" />
            </span>
          </div>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    // SMALL SCREEN
    <Grid
      container
      spacing={2}
      style={{ paddingTop: "20px", paddingLeft: "30px", paddingRight: "20px" }}
    >
      <Grid
        item
        xs={12}
        md={12}
        style={{ textAlign: "left" }}
        alignItems="left"
        justifyContent="left"
      >
        <span className="ourRes">Our Near-s</span>
        <span className="ItRes">IT</span>
        <span className="ourRes">e solutions</span>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ textAlign: "center" }}
        alignItems="center"
        justifyContent="center"
      >
        <p className="Nearsite-paragraphRes" style={{ textAlign: 'justify' }} >{messages.nearsiteParagraph}</p>
      </Grid>

      <Grid container spacing={2} style={{ paddingLeft:"10px",paddingTop: "10px" }}>
        <Grid item md={1}></Grid>
        <Grid item md={4}>
          <div className="SuperiorRes">
            <h2 className="SuperiorheadingRes">{messages.superior}</h2>

            <div className="SuperiorcontentRes">
              <span className="SuperiorcontentRes">
                {messages.superiorContent}
              </span>
            </div>
          </div>
        </Grid>
        <Grid item md={1}></Grid>

        <Grid item md={4}>
          <div className="CostRes">
            <h2 className="CostheadingRes">{messages.cost}</h2>

            <div className="CostcontentRes">
              <span>
                {messages.costContent}
              </span>
            </div>
          </div>
        </Grid>
        
      </Grid>
    </Grid>
  );
};

export default OurServices;
