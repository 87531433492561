import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <App>
      <Helmet>
        <title>Darsan Technologies, Inc.</title>
        <link rel="canonical" href="https://darsantech.com/#" />
        <meta name="description" content="
        Best IT consulting in Connecticut
        Best IT project rates
        Best IT consulting rates" />
      </Helmet>
      </App>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
