import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar  from './Components/Navbar';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    document.title = "Darsan Technologies, Inc."
 }, []);

  return (
    <div className="App">
      <Navbar/>
      
    </div>
  );
}

export default App;
