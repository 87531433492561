import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import RepeatGrid2 from "../Assets/RepeatGrid2.png";
import Design2 from "../Assets/Design2.png";
import "./index.css";
import { messages } from "./../Messages/messages.js";

const DesignItRight = (props) => {
  const desktop = useMediaQuery(useTheme().breakpoints.up("sm"));
  return desktop ? (
    <Grid
      container
      spacing={2}
      style={{ paddingTop: "10px", paddingLeft: "30px" }}
    >
      {/* HEADING */}
      <Grid item xs={12} md={6} style={{ paddingTop: "20px" }}>
        <div className="DesignItRight">{messages.designItRight}</div>
        <div className="FirstTime">{messages.firstTime}</div>
        <div className="Content" style={{ textAlign: "justify" }}>
          {messages.dIRContent}
        </div>

        <div style={{ paddingTop: "35px", paddingBottom:"35px"}}>
          <img src={RepeatGrid2} alt="RepeatGrid" style={{width:"730px", height:"42px"}}/>
        </div>
      </Grid>
      {/* IMAGE */}
      <Grid
        item
        xs={12}
        md={6}
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <div>
          <img src={Design2} alt="Design2" className="DesignImg" />
        </div>
      </Grid>
    </Grid>
  ) : (
    <Grid
      container
      spacing={2}
      style={{ paddingTop: "20px", paddingLeft: "30px", paddingRight: "30px" }}
    >
      {/* IMAGE */}
      <Grid
        item
        xs={12}
        md={7}
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <div>
          <img src={Design2} alt="Design2" className="DesignImgRes" />
        </div>
      </Grid>
      {/* HEADING */}
      <Grid item xs={12} md={5} style={{ paddingTop: "30px" }}>
        <div className="DesignItRightRes">{messages.designItRight}</div>
        <div className="FirstTimeRes">{messages.firstTime}</div>
        <div className="ContentRes" style={{ textAlign: "justify", hyphens: "auto" }}>
          {messages.dIRContent}
        </div>

        <div style={{ paddingTop: "20px" }}>
          <img src={RepeatGrid2} alt="RepeatGrid" className="Repeat" />
        </div>
      </Grid>
    </Grid>
  );
};

export default DesignItRight;
