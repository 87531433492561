import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ApartmentIcon from "@mui/icons-material/Apartment";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import footerimage from "../../Assets/footerimage.svg";
import "./style.css";
import { messages } from "./../../Messages/messages.js";

export const DarsanFooter = () => {
  const desktop = useMediaQuery(useTheme().breakpoints.up("sm"));
  return (
    <div style={{ paddingTop: "50px" }}>
      <div
        style={{
          backgroundColor: "#000",
          position: "relative",
          zIndex: 2,
          paddingBottom: "0px",
          paddingTop: "0px",
        }}
      >
        <Grid
          container
          sx={{
            paddingTop: "0px",
            paddingLeft: { md: "100px", sm: "30px", xs: "30px" },
            paddingRight: { xs: "30px", sm: "30px", md: 0 },
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                paddingTop: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{ color: "#FFE4CD", fontSize: "20px", height: "100%" }}
                >
                  <ApartmentIcon fontSize="inherit" color="inherit" />
                </span>
                <span
                  style={{
                    font: "normal normal normal 14px/24px Poppins",
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    display: "inline-block",
                    paddingLeft: "20px",
                    paddingTop: 0,
                    height: "100%",
                    textAlign: "left",
                  }}
                >
                  {/* <p style={{padding:0,background:'blue',height:"100%"}}> */}
                  {"Darsan Technologies, Inc."}
                  <br />
                  {"93 Shennecossett Road,"}
                  <br />
                  {"Groton, CT 06340"}
                  {/* </p> */}
                </span>
              </div>
            </div>
            <Grid
              item
              xs={12}
              sx={{
                paddingTop: "20px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                <span style={{ color: "#FFE4CD", fontSize: "20px" }}>
                  <EmailIcon fontSize="inherit" color="inherit" />
                </span>
                <span
                  style={{
                    font: "normal normal normal 14px/24px Poppins",
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    display: "inline-block",
                    paddingLeft: "20px",
                    height: "100%",
                    paddingTop: 6,
                    textAlign: "left",
                    justifyContent: "center",
                  }}
                >
                  {/* <p> */}
                  {"contact@darsantech.com"}
                  {/* </p> */}
                </span>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                paddingTop: "20px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
            </Grid>
          </div>

          {desktop && (
            <Grid
              item
              xs={12}
              md={9}
              style={{
                top: 70,
                position: "absolute",
                zIndex: 7,
                top: "15%",
                left: "48%",
              }}
            >
              <img src={footerimage} alt="IT consulting - New London, Groton, Waterford, New Haven, Hartford, Providence" />
            </Grid>
          )}
        </Grid>
      </div>
      <Grid
        item
        xs={12}
        md={12}
        style={{
          paddingTop: "20px",
          paddingBottom: "20px",
          textAlign: "center",
          font: "normal normal normal 14px/24px Poppins",
          letterSpacing: "0px",
          color: "#6C6B6B",
        }}
      >
        {messages.copyright}
      </Grid>
    </div>
  );
};