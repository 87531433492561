import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import welcome from '../../Assets/welcome.png';
import { messages } from '../../Messages/messages';
import './style.css';

const Welcome = () => {
    const desktop = useMediaQuery(useTheme().breakpoints.up('sm'));
    return (
        desktop?
       <Grid container spacing={2} style={{paddingTop:'80px',paddingLeft:'20px'}}>
        
                <Grid item xs={0} md={4} sm={0}>
                    <img src={welcome} alt="IT consulting near me" className='welcome-image' />
                </Grid>
        
         
            <Grid item xs={12} md={8}  style={{alignItems:'center'}}alignItems='center' justifyContent='center'>
            <Grid alignItems='center' style={{alignItems:'center',paddingLeft:'20px'}}justifyContent='center' >
                
                <Grid item xs={12} md={10}>
                    <p className='welcome-paragraph' style={{ textAlign: 'justify' }}>
                        {messages['welcome-paragraph']}
                    </p>
                </Grid>
            </Grid>
        </Grid>
        </Grid>
        :
       <Grid container spacing={2} style={{paddingTop:'10px',paddingLeft:'30px',paddingRight:'30px'}}>
        
        <Grid item xs={12} md={8} >
                <Grid container spacing={2} >
                    
                    <Grid item xs={12}>
                    <img src={welcome} alt="IT consulting near me" className='welcome-imageRes' />
                    </Grid>
                    <Grid item xs={12} style={{paddingTop:'5px'}} alignItems='left' justifyContent='left' >
                        <p className='welcome-paragraphRes' style={{ textAlign: 'justify' }}>
                            {messages['welcome-paragraph']}
                        </p>
                    </Grid>
                    
                </Grid>
            </Grid>
            </Grid>
     
    )
}

export default Welcome