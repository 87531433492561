export const messages = {
    "welcome-paragraph": "Our company motto is simple: Design IT Right - The First Time. We believe great technology solutions come from great design and in-depth client communication as well as managing sustainable budget goals. As a result, we understand the practical and innovative dimensions of technology development, transition, deployment, and maintenance. Darsan was founded by a team of tech experts with Fortune 500 experience but also an understanding of the entrepreneurial spirit. With our knowledge of advanced usability, large-scale software, and data-centric systems development, Darsan Technologies offers AI consulting, custom software products, user interface design, cloud solutions as well as a wide range of Information Technology managed services.",

    "designItRight": "Design IT Right",

    "firstTime": "The first time",

    "dIRContent": "Since 2001, Darsan Technologies has been providing industry leading consulting and IT services specifically designed to meet the needs of any organization: small, midsize or enterprise. Managed and owned by local IT professionals, our analytical approach to design and implementation allows Darsan to provide your organization with customized solutions that not only align with business objectives but also cater to the unique requirements of end-users. Solution delivery across various ERP’s including Business Central, SAP and Oracle with core capabilities in Cloud Migration, Cloud Solutions (AWS, Azure, Google), AI, Staff Augmentation, Managed Services, Full-Stack Development, Testing and Pharmacovigilance.",

    "copyright": "© Copyright 2024 - Darsan Technologies, Inc.",

    "approachDescription": "For every project, we follow our three step Design IT Right approach.",
    
    "approachName1": "Know The Business",

    "approachDescription1": "Everyone uses technology differently. With a constant focus on users and their roles, our solutions bring a better technology experience to end-users.",

    "approachName2": "Know The User",

    "approachDescription2": "Every business is different with its own set of challenges. Our vast experience across multiple industries allows us to understand and navigate the challenges that are unique to your business.",
    "approachName3": "Know The Technology",

    "approachDescription3": "Technology is not one size fits all. Finding the right technology for each project is our specialty.                                                                           ",

    "approachCaption": "Everyone uses technology differently. With a constant focus on users and their roles, our solutions bring a better technology experience to end-users.",

    "nearsiteParagraph": "Our near-sITe solution blends the best of both worlds: cost efficiency AND superior client service. Near-sITe replaces the traditional offshore model with local, highly skilled resources to liaise with clients resulting in superior Client - Darsan team synchronization. This model is radically different compared to the industry norm, culminating in minimal management overhead for project engagement and quicker project turnaround times. ",

    "superior": "Superior Client Service",

    "superiorContent": "A Darsan-direct contact ensures a complete understanding of business challenges, requirements and scope of the project.",

    "cost": "Cost Efficiency",

    "costContent": "A Darsan-approved pool of resources is managed via your Darsan-direct contact resulting in cost savings without a sacrifice in service.",

    "ourProducts": "Darsan Technologies offers a variety of specialized and ready-to-use software products for your business. Through our flagship product DARSANauditr, we offer a comprehensive solution for the Degree Audit needs of higher education institutions. Our compliance and course management product CoLEarn is designed to be a complete and cloud-ready solution to meet any organization’s legal,regulatory and internal compliance needs.",

    "darsanAuditr1": "DARSANauditr is a highly customizable Undergraduate Degree Audit application. This cloud-enabled, mobile-ready application has the ability to process general and customized academic requirements. DARSANauditr’s state-of-the-art user interface allows students, faculty and academic administrators to monitor and guide students’ progress toward graduation.",

    "darsanAuditr2": "Built with industry-standard development tools, DARSANauditr comes ready to be installed in a University/Department’s existing IT infrastructure or in the Cloud, with optional source code access. Designs based on flexible architecture allows easy integration of DARSANauditr into legacy application ecosystems.",

    "svcName1": "Custom Development",

    "svcDesc1": "We make IT easier, we make IT better, and we make IT more efficient! We blend information architecture,    business analysis, user need, process efficiency and graphic design to produce 100% functional applications with 100% usability in mind. Our expertise in mobility and web technologies enables us to deliver integrated, end-to-end solutions that streamline processes, increase efficiency, and drive business growth. Our commitment to staying at the forefront of technology and delivering outstanding customer service sets us apart in the industry. ",

    "svcName2": "Managed Services",

    "svcDesc2": "Do you need to keep those critical systems up and running at all times? Whether you need to increase system productivity or require 24x7 support, we can do that for you. With our flexible managed services options, you are free to run your business! ",

    "svcName3":"Application Support",

    "svcDesc3":"We can fix what ails your application! As your business evolves, applications need to change to suit new business scenarios. We are here to provide you with the maintenance and support required to extend the life of your applications.",

    "svcName4":"Legacy Technology",

    "svcDesc4":"Every business has legacy technologies still working for them and sometimes we just need to work with what we've got. We can help you with applications that need updates in older technologies that many others have thrown out to pasture!",

    "svcName5":"AI/Machine Learning",

    "svcDesc5":"For businesses to operate at their maximum potential in today's world, a robust and highly effective IT infrastructure is essential. At Darsan, we are focused on the future, and we can help you improve your IT infrastructure to manage your day-to-day operations skillfully.",

    "svcName6":"ERP",

    "svcDesc6":"Darsan Technologies is a provider of ERP (Enterprise Resource Planning) services. Our ERP solutions offer a comprehensive suite of modules that cover all business processes, from financial management to procurement and inventory control. Our ERP services are designed to streamline operations, reduce costs, and increase efficiency. We also offer customization options to meet specific business requirements. With our expertise in ERP implementation and maintenance. Darsan Technologies is a reliable partner for businesses seeking to optimize our operations and grow our bottom line. ",

    "svcName7":"Testing Services",

    "svcDesc7":"Do you have your own software products - in-house or third-party? We can provide you with state-of-the-art Quality Assurance services ranging from test plan development all the way through automated testing. ",
    "svcName8":"DevOps & Cloud Strategy",

    "svcDesc8":"Darsan Technologies offers DevOps and Cloud Engineering Services. We help businesses to modernize their IT systems, migrate to the cloud,and automate their software delivery processes. Our services include cloud architecture design, infrastructure management, continuous integration/continuous delivery (CI/CD) pipeline setup, and more. With our expertise, businesses can improve their agility, scalability, and reliability. Our tools can help you navigate the myriad of options available in the cloud computing arena to help you develop a comprehensive cloud strategy.",
    
    "svcName9":"Data Warehousing",

    "svcDesc9":"Darsan technologies provides Data Warehousing Services that enable organizations to gather, store, and manage large volumes of data efficiently. Our services include data integration, data modeling, data migration, and data quality management. With our expertise in building data warehouses. We ensure that businesses have access to accurate and up-to-date information, allowing them to make informed decisions. Our solutions are tailored to meet the unique needs of each organization, helping them to gain a competitive advantage in the market"
}
