import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import development from '../../Assets/mobility.svg';
import managedservices from '../../Assets/managedservices.svg';
import support from '../../Assets/support.svg';
import legacy from '../../Assets/legacy.svg';
import cloud from '../../Assets/cloud.svg';
import infrastructure from '../../Assets/infrastructure.svg';
import rpa from '../../Assets/rpa.svg';
import ecommerce from '../../Assets/ecommerce.svg';
import ModalDots from '../../Assets/ModalDots.svg';
import noc from '../../Assets/noc.svg';
import soc from '../../Assets/soc.svg';
import warehousing from '../../Assets/warehousing.svg';
import devops from '../../Assets/devops.svg';
import mobility from '../../Assets/mobility.svg';
import erp from '../../Assets/erp.svg';
import arrow from '../../Assets/arrow.svg';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import './style.css';
import { messages } from '../../Messages/messages';





const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

const services = [
    {
        id: 1,
        name: `${messages.svcName1}`,
        image: development,
        description:`${messages.svcDesc1}`
    },
    {
        id: 2,
        name: `${messages.svcName2}`,
        image: managedservices ,
        description:`${messages.svcDesc2}`
    },
    {
        id: 3,
        name: `${messages.svcName3}`,
        image: support,
        description:`${messages.svcDesc3}`
    },
    {
        id: 4,
        name: `${messages.svcName5}`,
        image: infrastructure,
        description:`${messages.svcDesc5}`
    },
    {
        id: 5,
        name: `${messages.svcName4}`,
        image: legacy,
        description:`${messages.svcDesc4}`
    },
    {
        id: 6,
        name: `${messages.svcName6}`,
        image: erp,
        description:`${messages.svcDesc6}`
    },
    {
        id: 7,
        name: `${messages.svcName7}`,
        image: rpa,
        description: `${messages.svcDesc7}`
    },
    {
        id: 8,
        name: `${messages.svcName8}`,
        image: devops,
        description:`${messages.svcDesc8}`
    },
    {
        id: 9,
        name: `${messages.svcName9}`,
        image: warehousing,
        description: `${messages.svcDesc9}`
    }

];

const OurServices = () => {


    const desktop = useMediaQuery(useTheme().breakpoints.up('sm'));


    const [open, setOpen] = React.useState(false);
    const [img, setImg] = React.useState(null);
    const [name, setName] = React.useState(null);
    const [description, setDescription] = React.useState(null);
    const [loadMore, setLoadMore] = React.useState(false);

    const handleClickOpen = (s) => {
        setOpen(true);
        setImg(s.image)
        setName(s.name)
        setDescription(s.description)
    };
    const handleClose = () => {
        setOpen(false);
    };
    const showMore = () => {
        // show more entries
        // switch to "show less"
        setLoadMore(true)
       }
    const showLess = () => {
        setLoadMore(false) 
    }
       const loadCount = loadMore ? services.length : 4;
    return (
        desktop ?
            // LARGE SCREEN
            <Grid container spacing={2} style={{ paddingTop: '50px', paddingLeft: '70px', paddingRight: '60px',justifyContent:'center',alignItems:'center' }}>
                <Grid item xs={12} md={12}>
                    <h2 className='our-services-heading fade-in'>
                        {'Our Services'}
                    </h2>
                </Grid>
                <Grid item xs={12} md={10} justifyContent={'center'} alignItems={'center'}>
                    <Grid container style={{ justifyContent:'center',alignItems:'center' }} spacing={0}>
                        {services.map((s) =>
                            <Grid item xs={12} sm={12} md={4}
                                key={s.id}
                                sx={{ padding: '10px', cursor: 'pointer' }}
                                onClick={() => handleClickOpen(s)}
                            >
                                {/* <div > */}
                                <div
                                    style={{
                                        background: ' #F9F8F4 0% 0% no-repeat padding-box',
                                        borderRadius: '10px', height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'
                                    }}>
                                    <Grid item xs={2} sx={{ paddingLeft: '22px', justifyContent: 'center', alignItems: 'center', textAlign: 'center', display: 'flex' }}>
                                        <img src={s.image} className='service-image' alt={s.name} />
                                    </Grid>
                                    <Grid item xs={8} sx={{ paddingLeft: '22px',paddingTop:'10px' }}>
                                        <h6 className='service-name'>
                                            {s.name}
                                        </h6>
                                    </Grid>
                                    <Grid item xs={2} sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', display: 'flex' }}>
                                        <img src={arrow} className='service-arrow' alt='arrow' />
                                    </Grid>
                                </div>
                                {/* </div> */}
                            </Grid>
                        )}
                        <BootstrapDialog
                         fullWidth={true}
                         maxWidth={'xs'}
                            onClose={handleClose}
                            aria-labelledby="customized-dialog-title"
                            open={open}
                        >
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                                <div style={{ display: 'flex' , alignItems: 'center', textAlign: 'center'}}>
                                    <span style={{ paddingLeft: '22px', justifyContent: 'center', alignItems: 'center', textAlign: 'center', display: 'flex' }}>
                                        <img src={img} className='service-image' alt={name} />
                                    </span>
                                    <span style={{ paddingLeft: '22px', justifyContent: 'center', alignItems: 'center', textAlign: 'center', display: 'flex' }}>
                                        <h6 className='service-name'>
                                            {name}
                                        </h6>
                                    </span>
                                </div>
                            </BootstrapDialogTitle>
                            <DialogContent  style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'justify', display: 'flex' }}>
                                <p className='dialogContent'>
                                    {description}
                                </p>

                            </DialogContent>
                                    <DialogActions  style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', display: 'flex' }}>
                                    <img src={ModalDots} className='dot-image' alt={name} />
                                    </DialogActions>
                        </BootstrapDialog>
                    </Grid>
                </Grid>
            </Grid>

            :

            // SMALL SCREEN
            <Grid container spacing={2} style={{ paddingTop: '180px', paddingLeft: '30px', paddingRight: '10px' }}>
                <Grid item xs={12} md={12}>
                    <h2 className='our-services-headingRes'>
                        {'Our Services'}
                    </h2>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container>
                        {services.slice(0,loadCount).map((s) =>
                            <Grid item xs={12} sm={12} md={12}
                                key={s.id}
                                sx={{ cursor: 'pointer',paddingBottom:'20px' }}
                                onClick={() => handleClickOpen(s)}
                            >
                                {/* <div > */}
                                <div
                                    style={{
                                        background: '#F9F8F4 0% 0% no-repeat padding-box',
                                        width:'312px',
                                        borderRadius: '10px', height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'
                                    }}>
                                    <Grid item xs={2} sx={{ paddingLeft: '18px', justifyContent: 'center', alignItems: 'center', textAlign: 'center', display: 'flex' }}>
                                        <img src={s.image} className='service-image' alt={s.name} />
                                    </Grid>
                                    <Grid item xs={8} sx={{ paddingLeft: '22px' }}>
                                        <h6 className='service-nameRes'>
                                            {s.name}
                                        </h6>
                                    </Grid>
                                    <Grid item xs={2} sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', display: 'flex' }}>
                                        <img src={arrow} className='service-arrow' alt='arrow' />
                                    </Grid>
                                </div>
                                {/* </div> */}
                            </Grid>
                        )}
                         <Grid item xs={12} sm={12} md={12}
                                sx={{ cursor: 'pointer',justifyContent: 'center', alignItems: 'center', textAlign: 'center', display: 'flex' }}
                            >
                        {loadMore?
                                                <p >
                                                <a className="btn" onClick={showLess}>Show Less</a>
                                            </p>
                                                :
                                                    <p>
                                <a className="btn" onClick={showMore}>Show more ....</a>
                                </p>
                            }
                            </Grid>
                        <BootstrapDialog
                            onClose={handleClose}
                            aria-labelledby="customized-dialog-title"
                            open={open}
                        >
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                                <div style={{ display: 'flex' }}>
                                    <span style={{ paddingLeft: '22px', justifyContent: 'center', alignItems: 'center', textAlign: 'center', display: 'flex' }}>
                                        <img src={img} className='service-image' alt={name} />
                                    </span>
                                    <span style={{ paddingLeft: '22px' }}>
                                        <h6 className='service-name'>
                                            {name}
                                        </h6>
                                    </span>
                                </div>
                            </BootstrapDialogTitle>
                            <DialogContent >
                                <p className='dialogContent'>
                                  {description}
                                </p>
                            </DialogContent>
                        </BootstrapDialog>
                    </Grid>
                </Grid>
            </Grid>
    )
}

export default OurServices