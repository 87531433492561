import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import clientimg1 from '../../Assets/clientimg1.png';
import clientimg3 from '../../Assets/clientimg3.png';
import clientimg4 from '../../Assets/clientimg4.png';
import clientimg_5grey from '../../Assets/clientimg_5grey.png';
import clientimg6 from '../../Assets/clientimg6.png';
import Carousel from "react-multi-carousel";
import './index.css';
import "react-multi-carousel/lib/styles.css";


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
const Client=()=>{
    return(
        <Grid container spacing={2} style={{paddingTop:'30px'}}>
        <Grid item xs={12} md={12} style={{textAlign:'center'}} alignItems='center' justifyContent='center'>
            <span className='our'>
                Our Clients
            </span>
          
        </Grid>
        <Grid item xs={12} md={12} style={{textAlign:'center'}} alignItems='center' justifyContent='center'>
        <Carousel responsive={responsive} showDots={false}  autoPlay={true} swipeable={true}
  draggable={true} infinite={true} arrows={false}>
          <div><img src={clientimg1} alt="IT consulting education" width="169px" height="89px"/></div>
          <div><img src={clientimg3} alt="IT consulting for finance" width="169px" height="89px" /></div>
          <div><img src={clientimg4} alt="IT services for small to enterprise clients"  width="169px" height="89px"/></div>
          <div><img src={clientimg_5grey} alt="IT solutions for healthcare"  width="169px" height="89px"/></div>
          <div><img src={clientimg6} alt="IT consulting for small businesses"  width="169px" height="89px"/></div>
          <div><img src={clientimg3} alt="IT consulting for finance" width="169px" height="89px" /></div>
          <div><img src={clientimg4} alt="IT services for small to enterprise clients"  width="169px" height="89px"/></div>
          
        </Carousel>
        </Grid>
        </Grid>
    )
}

export default Client