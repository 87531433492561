import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import landscape from '../../Assets/landscape.png';
import LandscapeRes from '../../Assets/LandscapeRes.png';
import compliance from '../../Assets/compliance.svg';
import education from '../../Assets/education.svg';
import pharmaceuticals from '../../Assets/pharmaceuticals.svg';
import health from '../../Assets/health.svg';
import finance from '../../Assets/finance.svg';
import hospitality from '../../Assets/hospitality.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './style.css';

const landscapes = [
    {
        id: 1,
        name: 'Compliance/Regulatory',
        image: compliance,
        alt: 'IT consulting for legal firms'
    },
    {
        id: 2,
        name: 'Higher Education',
        image: education,
        alt: 'IT consulting education'
    },
    {
        id: 3,
        name: 'Pharmaceuticals',
        image: pharmaceuticals,
        alt: 'IT consulting  for Pharma'
    },
    {
        id: 4,
        name: 'Health Care',
        image: health,
        alt: 'IT consulting  for healthcare'
    },
    {
        id: 5,
        name: 'Financials',
        image: finance,
        alt: 'IT consulting  for finance'
    },
    {
        id: 6,
        name: 'Hospitality',
        image: hospitality,
        alt: 'IT consulting  for Hospitality'
    }
];

export const SystemLandscape = () => {
    const desktop = useMediaQuery(useTheme().breakpoints.up('sm'));
    return (
        <div style={{paddingLeft:"0px", paddingRight:"0px"}}>
        <Grid container className='landscape-background'  style={{justifyContent:'center',alignItems:'center'}}>
            <Grid item xs={12} md={10}>
                <Grid container>
            {desktop ?
                null
                :
                <div style={{paddingLeft:"30px", paddingRight:"20px", paddingBottom:"40px"}}>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={LandscapeRes} alt='System Landscape' className='landscape-imageRes' />
                </Grid></div>}
            <Grid container className='landscape-background' sx={{ paddingLeft: {md:'80px',xs:0} }}>
                {desktop &&
                    <Grid item xs={0} md={4} >
                        <img src={landscape} alt='IT consulting for small businesses' className='landscape-image' />
                    </Grid>
                }
                <Grid item xs={12} md={8}
                sm={12}
                    display='flex'
                    justifyContent='center'
                    alignItems={'center'}
                    sx={{ justifyContent: 'left', paddingLeft: "20px", paddingRight: "20px" ,width:'100%'}}>
                    <div>
                                <h6  className={desktop ? 'landscape-heading' : 'landscape-headingRes'}>
                                    {'Solution Domains'}
                                </h6>
                        
                        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' ,paddingTop:'15px',width:'100%',}}>
                            {landscapes.map((l) =>
                                <Col xs={12} md={6} key={l.id}
                                    style={{ paddingBottom: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                    {desktop ?
                                        // LARGE SCREEN
                                        <div
                                            style={{
                                                width: '100%',
                                                background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                height: '100px',
                                                borderRadius: '10px',
                                                display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'
                                            }}>
                                            <Grid item xs={2} md={2} style={{ paddingLeft: 5 }}>
                                                <img src={l.image} alt={l.alt} className='landscape-icon' />
                                            </Grid>
                                            <Grid item xs={10} md={10} style={{ paddingLeft: 5,paddingTop:'10px'}}>
                                                <h5 className='landscape-name'>
                                                    {l.name}
                                                </h5>
                                            </Grid>
                                        </div>
                                        :
                                        //  SMALL SCREEN
                                        <div
                                            style={{
                                                width: '350px',
                                                background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                height: '80px',
                                                borderRadius: '10px',
                                                display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'
                                            }}>
                                                <Row style={{width:'100%',paddingLeft:'3px'}}>
                                            <Col item xs={2} md={2} style={{ paddingLeft: 1 }}>
                                                <img src={l.image} alt={l.alt} className='landscape-icon' />
                                            </Col>
                                            <Col xs={10} md={10} style={{  display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                                                <h6 className='landscape-name' style={{textAlign:'left',fontSize:"18px"}}>
                                                    {l.name}
                                                </h6>
                                            </Col>
                                            </Row>
                                        </div>
                                    }
                                </Col>
                            )}
                        </Row>
                        {/* </Grid> */}
                    </div>
                </Grid>
            </Grid>
            </Grid>
            </Grid>
        </Grid>
        </div>
    )
}
